'use strict';

module.exports = {
  OfficeDocument: 'http://schemas.openxmlformats.org/officeDocument/2006/relationships/officeDocument',
  Worksheet: 'http://schemas.openxmlformats.org/officeDocument/2006/relationships/worksheet',
  CalcChain: 'http://schemas.openxmlformats.org/officeDocument/2006/relationships/calcChain',
  SharedStrings: 'http://schemas.openxmlformats.org/officeDocument/2006/relationships/sharedStrings',
  Styles: 'http://schemas.openxmlformats.org/officeDocument/2006/relationships/styles',
  Theme: 'http://schemas.openxmlformats.org/officeDocument/2006/relationships/theme',
  Hyperlink: 'http://schemas.openxmlformats.org/officeDocument/2006/relationships/hyperlink',
  Image: 'http://schemas.openxmlformats.org/officeDocument/2006/relationships/image',
  CoreProperties: 'http://schemas.openxmlformats.org/package/2006/relationships/metadata/core-properties',
  ExtenderProperties: 'http://schemas.openxmlformats.org/officeDocument/2006/relationships/extended-properties',
};
